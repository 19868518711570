import React, { forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IconButton, Spacer } from 'styles/Global'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  TIME_FRAME_ENUM, TIME_FRAME_ENUM_NO_CUSTOM, TIME_FRAME_ICON_COLORS,
  TIME_FRAME_ICONS,
  TIME_FRAME_LABELS,
  TimeFrame
} from 'helper/datetime/timeFrame'

type TimeFrameDropdownProps = {
  style?: "BUTTON" | "HEADER";
  customEnabled?: boolean;
  timeFrame: TimeFrame;
  setTimeFrame: (timeFrame: TimeFrame) => void;
  transformUppercase?: boolean;
}

const Row = styled.div`
  display: flex;
  align-items: center;
  text-transform: none;
`;

const Header = styled.button`
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 600;
  color: var(--secondary-label-color);

  &.uppercase {
    text-transform: uppercase;
  }
`;

const Item = ({ val, setVal, active }) => (
  <Dropdown.Item onClick={() => setVal(val)}>
    <Row>
      <FontAwesomeIcon icon={TIME_FRAME_ICONS[val]} color={TIME_FRAME_ICON_COLORS[val]} />
      &nbsp;&nbsp;
      <span>{TIME_FRAME_LABELS[val] || "Invalid"}</span>
      <Spacer style={{ minWidth: 100 }} />
      {val === active && <FontAwesomeIcon icon={faCheck} color="var(--devo-blue)" />}
    </Row>
  </Dropdown.Item>
)

const TimeFrameDropdown = ({ style = "BUTTON", customEnabled = true, timeFrame, setTimeFrame, transformUppercase = true } : TimeFrameDropdownProps) => {
  const arrToUse = customEnabled ? TIME_FRAME_ENUM : TIME_FRAME_ENUM_NO_CUSTOM;
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} label={TIME_FRAME_LABELS[timeFrame]} transformUppercase={transformUppercase} variant={style} />

      <Dropdown.Menu>
        {arrToUse.map((item, index) => (
          <Item key={index} active={timeFrame} val={item} setVal={setTimeFrame} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

type CustomToggleProps = {
  variant: string;
  label: string;
  onClick: any;
  transformUppercase?: boolean;
}

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(({ onClick, label, variant, transformUppercase = true }, ref) => (
  <div>
    {variant === "BUTTON" &&
      <IconButton ref={ref} onClick={onClick} icon={faCalendarAlt}>
        {label}
      </IconButton>
    }
    {variant === "HEADER" &&
      <Header className={transformUppercase && 'uppercase'} ref={ref} onClick={onClick}>
        Sales {label}
        &nbsp;
        <FontAwesomeIcon icon={faCaretDown} />
      </Header>
    }
  </div>
));

export default TimeFrameDropdown